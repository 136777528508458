import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout2"
import HomeBanner from "../components/homeBanner"
import Mapa from '../components/mapa'
import SEO from "../components/seo"
import {
    Container
} from 'react-bootstrap'

export default function cursoTemplate({
    data, 
}) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    let keyw = [`andes`, 
    `capacitacion`,
    `efectiva`,
    `andes capacitacion`,
    `capacitacion efectiva`,
    `andes capacitacion efectiva`,
    `sence`,
    `capacitacion sence`,
    `norma`,
    `NCH:2728`,
    `norma nch:2728`,
    `otec`,
    `atacama`,
    `otec atacama`,
    `copiapo`,
    `otec copiapo`,
    `chile`,
    `mineria`,
    `otec mineria`,
    `camion`,
    `camion pluma`,
    `cargador`,
    `cargador frontal`,
    `minicargador`,
    `minicargador frontal`,
    `extintor`,
    `extintores`,
    `primeros auxilios`,
    `auxilios`,
    `altura`,
    `trabajo en altura`,
    `trabajo altura`,
    `maquinaria`,
    `pesada`,
    `maquinaria pesada`,
    `manejo`,
    `alta`,
    `montana`,
    `manejo alta montana`,
    `manejo defensivo`,
    `manejo defensivo alta montana`,
    `izaje`,
    `rigger`,
    `izaje y rigger`,
    `transporte`,
    `almacenamiento`,
    `sustancias`,
    `peligrosas`,
    `manejo sustancias`,
    `manejo sustancias peligrosas`,
    `almacenamiento sustancias`,
    `almacenamiento sustancias peligrosas`,
    `transporte sustancias`,
    `transporte sustancias peligrosas`,
    `manejo, almacenamiento y transporte de sustancias`,
    `manejo, almacenamiento y transporte de sustancias peligrosas`,
    `manejo, transporte y almacenamiento de sustancias`,
    `manejo, transporte y almacenamiento de sustancias peligrosas`,
    `transporte, manejo y almacenamiento de sustancias`,
    `transporte, manejo y almacenamiento de sustancias peligrosas`,
    `transporte, almacenamiento y manejo de sustancias`,
    `transporte, almacenamiento y manejo de sustancias peligrosas`,
    `almacenamiento, transporte y manejo de sustancias`,
    `almacenamiento, transporte y manejo de sustancias peligrosas`,
    `almacenamiento, manejo y transporte de sustancias`,
    `almacenamiento, manejo y transporte de sustancias peligrosas`,
    `tecnicas`,
    `tegnicas de operacion`,
    `tecnicas en la operacion`,
    `tecnicas de operacion de camion`,
    `tecnicas en la operacion de camion`,
    `tecnicas de operacion de camion pluma`,
    `tecnicas en la operacion de camion pluma`,
    `retroexcavadora`,
    `operacion segura`,
    `puente grua`,
    `operacion puente grua`,
    `operacion segura de puente grua`,
    `tecnicas de operacion segura`,
    `tecnicas de operacion segura de retroexcavadora`,
    `plataformas elevadoras`,
    `operacion segura de plataformas elevadoras`,
    `tecnicas de operacion segura de plataformas elevadoras`,
    `gruas`,
    `levante`,
    `grua`,
    `levante de material`,
    `izaje de material`,
    `levante e izaje`,
    `izaje y levante`,
    `izaje y levante con gruas`,
    `levante e izaje con gruas`,
    `levante e izaje de material`,
    `izaje y levante de material`,
    `levante e izaje de material con gruas`,
    `izaje y levante de material con gruas`,
    `tecnicas de izaje y levante con gruas`,
    `tecnicas de levante e izaje con gruas`,
    `tecnicas de izaje y levante de material`,
    `tecnicas de levante e izaje de material`,
    `tecnicas de izaje y levante de material con gruas`,
    `tecnicas de levante e izaje de material con gruas`,
    `operacion cargador`,
    `operacion minicargador`,
    `operacion cargador frontal`,
    `operacion segura cargador frontal`,
    `operacion minicargador frontal`,
    `operacion segura minicargador`,
    `operacion segura minicargador frontal`,
    `tecnicas de operacion cargador`,
    `tecnicas de operacion cargador frontal`,
    `tecnicas de operacion segura cargador`,
    `tecnicas de operacion segura cargador frontal`,
    `tecnicas de operacion minicargador`,
    `tecnicas de operacion minicargador frontal`,
    `tecnicas de operacion segura minicargador`,
    `tecnicas de operacion segura minicargador frontal`,
    `mantencion`,
    `reparacion`,
    `operacion y mantencion`,
    `mantencion y operacion`,
    `operacion, mantencion y reparacion`,
    `operacion, reparacion y mantencion`,
    `mantencion, operacion y reparacion`,
    `mantencion, reparacion y operacion`,
    `reparacion, operacion y mantencion`,
    `reparacion, mantencion y operacion`,
    `tecnicas de operacion, mantencion y reparacion`,
    `tecnicas de operacion, reparacion y mantencion`,
    `tecnicas de mantencion, operacion y reparacion`,
    `tecnicas de mantencion, reparacion y operacion`,
    `tecnicas de reparacion, operacion y mantencion`,
    `tecnicas de reparacion, mantencion y operacion`,
    `sistemas de aire acondicionado`,
    `aire acondicionado`,
    `operacion y mantencion de aire acondicionado`,
    `mantencion y operacion de aire acondicionado`,
    `operacion y mantencion de sistemas de aire acondicionado`,
    `mantencion y operacion de sistemas de aire acondicionado`,
    `operacion, mantencion y reparacion de aire acondicionado`,
    `operacion, reparacion y mantencion de aire acondicionado`,
    `mantencion, operacion y reparacion de aire acondicionado`,
    `mantencion, reparacion y operacion de aire acondicionado`,
    `reparacion, operacion y mantencion de aire acondicionado`,
    `reparacion, mantencion y operacion de aire acondicionado`,
    `tecnicas de operacion, mantencion y reparacion de aire acondicionado`,
    `tecnicas de operacion, reparacion y mantencion de aire acondicionado`,
    `tecnicas de mantencion, operacion y reparacion de aire acondicionado`,
    `tecnicas de mantencion, reparacion y operacion de aire acondicionado`,
    `tecnicas de reparacion, operacion y mantencion de aire acondicionado`,
    `tecnicas de reparacion, mantencion y operacion de aire acondicionado`,
    `operacion y mantencion de sistemas de sistemas de aire acondicionado`,
    `mantencion y operacion de sistemas de sistemas de aire acondicionado`,
    `operacion, mantencion y reparacion de sistemas de aire acondicionado`,
    `operacion, reparacion y mantencion de sistemas de aire acondicionado`,
    `mantencion, operacion y reparacion de sistemas de aire acondicionado`,
    `mantencion, reparacion y operacion de sistemas de aire acondicionado`,
    `reparacion, operacion y mantencion de sistemas de aire acondicionado`,
    `reparacion, mantencion y operacion de sistemas de aire acondicionado`,
    `tecnicas de operacion, mantencion y reparacion de sistemas de aire acondicionado`,
    `tecnicas de operacion, reparacion y mantencion de sistemas de aire acondicionado`,
    `tecnicas de mantencion, operacion y reparacion de sistemas de aire acondicionado`,
    `tecnicas de mantencion, reparacion y operacion de sistemas de aire acondicionado`,
    `tecnicas de reparacion, operacion y mantencion de sistemas de aire acondicionado`,
    `tecnicas de reparacion, mantencion y operacion de sistemas de aire acondicionado`,
    `curso camion`,
    `curso camion pluma`,
    `cursos`,
    `curso`,
    `curso extintores`]
    frontmatter.tags.map( tag => {
        keyw = keyw.concat(tag)
        return keyw
    })
    return (
        <Layout>
            <SEO title={`Andes CCI - ${frontmatter.curso}`} 
            description={`Andes Capacitación Ltda. Curso ${frontmatter.curso}. ${frontmatter.descripcion}`}
            keywords={keyw} />
            <HomeBanner />
            <div id="nuestros-cursos">
                <Container>
                    <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>{ frontmatter.curso }</h1>
                    <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "9%", marginBottom: "1%" }}>Descripción: </h1>
                    <div id="nuestros-cursos-text" className="text-justify">
                        <p>
                            { frontmatter.descripcion }
                        </p>
                    </div>
                    <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "3%", marginBottom: "1%" }}>Contenidos: </h1>
                    <div id="nuestros-cursos-text" className="text-justify puntos-cursos">
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                    <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "3%", marginBottom: "1%" }}>Duración: </h1>
                    <div id="nuestros-cursos-text" className="text-justify" style={{ color: "#FF6751" }}>
                        <ul>
                            <li>{ frontmatter.duracion }</li>
                        </ul>        
                    </div>
                </Container>
            </div>
            <Mapa />
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                curso
                descripcion
                duracion
                tags
            }
        }
    }
`
