import React, { Component } from 'react'

export default class mapa extends Component {
    render() {
        return (
            <div id="mapa">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.4322452318575!2d-70.34120638528097!3d-27.362213118690235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9698038adff144f5%3A0xc236404d5702d557!2sRancagua+420%2C+Copiap%C3%B3%2C+Regi%C3%B3n+de+Atacama!5e0!3m2!1ses-419!2scl!4v1557846075921!5m2!1ses-419!2scl"
                    width="100%" height="500" frameBorder="0" allowFullScreen title="mapa-andes"></iframe>
            </div>
        )
    }
}
