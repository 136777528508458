import React, { Component } from 'react'
import {
    Container,
    Form,
    Row,
    Col,
    Button
} from 'react-bootstrap'
import logo_blanco from '../images/logo-blanco.png'
import { FaPhone, FaEnvelope, FaFacebookF, FaLinkedinIn, FaMapMarkedAlt } from 'react-icons/fa'

export default class footer extends Component {
    render() {
        return (
            <footer id="footer">
                <Container>
                    <Row>
                        <Col md="4">
                            <h1 className="font-weight-bold" style={{ color: "#fff", marginBottom: "20%" }}>CONTA<span id="linea-cto">CTO</span></h1>
                            <Form method="POST" action="https://formspree.io/contacto@andescci.com">
                                <Form.Group>
                                    <Form.Control name="Nombre" type="text" placeholder="Nombre" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control name="Correo" type="text" placeholder="Email" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control name="Celular" type="text" placeholder="Celular" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control name="Mensaje" as="textarea" placeholder="Escribir mensaje" />
                                </Form.Group>
                                <Button type="submit">ENVIAR</Button>
                            </Form>
                        </Col>
                        <Col md="4">
                            <Row>
                                <Col md="12">
                                    <img src={logo_blanco} alt="Andes" style={{ width: "100%", marginTop: "15%" }} />
                                </Col>
                            </Row>
                            <Row style={{padding:"0", margin: "0", height: "12%"}}>
                                <Col id="footer-email" md="12" style={{ paddingTop: "2%" }}><span><FaEnvelope style={{ marginBottom: "4px", fontSize: "20px", marginRight: "5px" }} /> contacto@andescci.com</span></Col>
                            </Row>
                            <Row style={{padding:"0", margin: "0", height: "13%"}}>
                                <Col id="footer-phone" md="12" style={{ paddingTop: "4%",  }}><span><FaPhone style={{ marginBottom: "4px", marginRight: "3px", transform: "rotate(100deg)" }} /> 52-2253699</span></Col>
                            </Row>
                            <Row style={{padding:"0", margin: "0"}}>
                                <Col id="footer-location" md="12" style={{ paddingTop: "6%", lineHeight: "24px" }}><span><FaMapMarkedAlt style={{ marginBottom: "4px", marginRight: "8px" }} /> Rancagua #420<br /><p style={{ textIndent: "38px" }}>Copiapó - Chile</p></span></Col>
                            </Row>
                            <Row>
                                <Col md="12" style={{ marginTop: "5%", textAlign: "center" }}>
                                    <span style={{ fontSize: "12px" }}>Visitanos también en:</span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "2%" }}>
                                <Col md="12" style={{ textAlign: "center" }}>
                                    <span><a href="https://www.facebook.com/andescci/"><FaFacebookF style={{ fontSize: "20px", marginBottom: "5px", color: "white" }} /></a></span>
                                    <span><FaLinkedinIn style={{ fontSize: "25px", marginBottom: "4px", marginLeft: "5px" }} /></span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="4">
                            
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}
